import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import MaterialTable from 'material-table'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Link from '@material-ui/core/Link'
import Dialog from '@material-ui/core/Dialog'

const SimpleDialog = (props) => {
  const { onClose, open, preview, handleSubmit } = props

  const handleClose = () => {
    onClose()
  }

  const _renderTableData = () => {
    if (!preview) return <div className='allocator__empty-table'>No csv here!</div>

    return (
      <div className='preview-container'>
        <table className='receipt-upload-preview'>
          <thead>
            <tr>
              <th>Supplier Name</th>
              <th>Inventory Name</th>
              <th>Quantity Expected</th>
              <th>Quantity Received</th>
              <th>Shipped<br />At</th>
              <th>Received<br />At</th>
              <th>Tracking<br />Url</th>
            </tr>
          </thead>
          <tbody>
            { preview.map((row, idx) => (
              <tr key={ `row${ idx }` }>
                <td>{ row.supplierShortCode }</td>
                <td>{ row.inventoryItemName }</td>
                <td>{ row.quantityExpected }</td>
                <td>{ row.quantityReceived }</td>
                <td>{ row.shippedAt }</td>
                <td>{ row.receivedAt }</td>
                <td>{ row.trackingUrl }</td>
              </tr>
            ))
            }
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <Dialog aria-labelledby="simple-dialog-title" onClose={ handleClose } open={ open } maxWidth={ 'md' }>
      <div className='dialog-btn-wrapper'>
        <Button onClick={ handleSubmit } variant='contained' color='secondary'>
          Upload
        </Button>
        <Button onClick={ handleClose } variant='outlined' color='primary'>
          Cancel
        </Button>
      </div>
      { _renderTableData() }
    </Dialog>
  )
}

export default function ReceiptLines(props) {
  const { match, tableHeader, centerStyle } = props
  const [snack, setSnack] = useState('')
  const [receiptLines, setReceiptLines] = useState([])
  const [inventoryNames, setInventoryNames] = useState([])
  const [suppliers, setSuppliers] = useState([])
  const [showPreview, setShowPreview] = useState(false)
  const [preview, setPreview] = useState([])

  const receiptLineStateMap = { created: 'Created', shipped: 'Shipped', received: 'Received' }
  const history = useHistory()

  useEffect(() => {
    const getReceiptLines = async () => {
      const path = `/api/receipts/${ match.params.receiptNumber }/receiptLines`
      const response = await fetch(path)
      if (response.status !== 200) throw Error(response.json().body)
      return response.json()
    }

    const getInventoryNames = async () => {
      const path = `/api/inventoryItems`
      const response = await fetch(path)
      if (response.status !== 200) throw Error(response.json().body)
      return response.json()
    }

    const getSuppliers = async () => {
      const path = `/api/suppliers`
      const response = await fetch(path)
      if (response.status !== 200) throw Error(response.json().body)
      return response.json()
    }

    getReceiptLines()
      .then(({ receipt_lines }) => {
        if (receipt_lines) setReceiptLines(receipt_lines)
      })

    getInventoryNames()
      .then(({ variant_inventory_items }) => {
        if (variant_inventory_items) setInventoryNames(variant_inventory_items)
      })

    getSuppliers()
      .then(({ suppliers }) => {
        if (suppliers) setSuppliers(suppliers)
      })
  }, [match.params.receiptNumber])

  const createLineItem = async (newData) => {
    const path = `/api${ match.url }/receiptLines`
    const body = newData
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    }

    const response = await fetch(path, options)
    if (response.status !== 200) throw Error(response.json().body)
    return response.json()
  }

  const updateLineItem = async (newData) => {
    const path = `/api${ match.url }/receiptLines/${ newData.id }`
    const body = newData
    const options = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    }

    const response = await fetch(path, options)
    if (response.status !== 200) throw Error(response.json().body)
    return response.json()
  }

  const deleteLineItem = async (oldData) => {
    const path = `/api${ match.url }/receiptLines/${ oldData.id }`
    const options = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' }
    }

    const response = await fetch(path, options)
    if (response.status !== 200) throw Error(response.json().body)
    return response.json()
  }

  const _handleRowAdd = async (newData) => {
    try {
      const responseJson = await createLineItem(newData)
      const receiptLine = responseJson.receipt_line

      if (receiptLine) {
        setReceiptLines((prevState) => {
          const data = [...prevState]
          data.push(receiptLine)
          return data
        })
      } else if (responseJson.error) {
        setSnack(`Error: ${ responseJson.error }`)
      }
    } catch (e) {
      // TODO: Honeybadger log it
      console.log(e)
      setSnack('Something went wrong :(')
    }
  }

  const _handleRowUpdate = async (newData, oldData) => {
    try {
      const responseJson = await updateLineItem(newData)
      const receiptLine = responseJson.receipt_line

      if (receiptLine) {
        setReceiptLines((prevState) => {
          const data = [...prevState]
          data[data.indexOf(oldData)] = receiptLine
          return data
        })
      } else if (responseJson.error) {
        setSnack(`Error: ${ responseJson.error }`)
      }
    } catch (e) {
      // TODO: Honeybadger log it
      console.log({ e })
      setSnack('Something went wrong :(')
    }
  }

  const _handleRowDelete = async (oldData) => {
    try {
      const responseJson = await deleteLineItem(oldData)
      const receiptLine = responseJson.receipt_line

      if (receiptLine) {
        setReceiptLines((prevState) => {
          const data = [...prevState]
          data.splice(data.indexOf(oldData), 1)
          return data
        })
      } else if (responseJson.error) {
        setSnack(`Error: ${ responseJson.error }`)
      }
    } catch (e) {
      // TODO: Honeybadger log it
      console.log({ e })
      setSnack('Something went wrong :(')
    }
  }

  const _supplierNames = (row) => {
    const supplier = suppliers.find(s => s.id === row.supplier_id)
    if (supplier) return supplier.name
  }

  const _inventoryNames = (row) => {
    const inventoryName = inventoryNames.find(inv => inv.id === row.inventory_item_id)
    if (inventoryName) return inventoryName.inventory_name
  }

  const _handleClose = (event, reason) => {
    if (reason === 'clickaway') return
    setSnack(null)
  }

  const upload = async (csv) => {
    const formData = new FormData()
    formData.append('csvFile', csv)

    const path = `/api/receipts/${ match.params.receiptNumber }/receiptLines/upload`
    const options = { method: 'POST', body: formData }

    const response = await fetch(path, options)
    const responseJson = await response.json()

    if (response.status !== 200) _handleError(responseJson)

    return responseJson
  }

  const submit = async () => {
    const path = `/api/receipts/${ match.params.receiptNumber }/receiptLines/submit`
    const body = { receiptLines: preview }
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    }

    const response = await fetch(path, options)
    const responseJson = await response.json()

    if (response.status !== 200) _handleError(responseJson)

    return responseJson
  }

  const _handleFileChange = (e) => {
    if (e.target.files.length) {
      const csv = e.target.files[0]

      upload(csv).then((res) => {
        setPreview(res)
        handleModalOpen()
      })
    } else {
      setPreview([])
    }
  }

  const handleModalClose = () => {
    setShowPreview(false)
    setPreview([])
    _handleClearPreview()
  }

  const handleModalOpen = () => {
    setShowPreview(true)
  }

  const _handleClearPreview = () => {
    var file = document.forms[0].elements

    try {
      file[0].value = ''
    } catch (e) {
      console.log('Something bad happened', e)
    }
  }

  const _handleSubmitPreview = (e) => {
    e.preventDefault()

    try {
      submit()
        .then(res => {
          setShowPreview(false)
          setPreview([])
          setSnack('Upload Successful!')

          // TODO: Temporary until we implement better way to re-fetch newly created data.
          setTimeout(() => {
            reloadReceiptLines()
              .then(({ receipt_lines }) => {
                if (receipt_lines && receipt_lines.length) setReceiptLines(receipt_lines)
              })
          }, 3000)
        })
    } catch (e) {
      console.log('Error', e)
      setSnack('An error has occurred during file upload!')
    }
  }

  const reloadReceiptLines = async () => {
    const path = `/api/receipts/${ match.params.receiptNumber }/receiptLines`
    const response = await fetch(path)
    const responseJson = await response.json()
    if (response.status !== 200) _handleError(responseJson)
    return responseJson
  }

  const _handleError = (errors) => {
    // TODO: display errors to user
    console.log('Errors:', errors)
    setSnack('An error has occurred during file upload!')
  }

  return (
    <div className='table__container'>
      { snack &&
        <Snackbar
          anchorOrigin={ {
            vertical: 'bottom',
            horizontal: 'left',
          } }
          open={ snack ? true : false }
          autoHideDuration={ 6000 }
          onClose={ _handleClose }
          message={ snack }
          action={
            <Button color='primary' size='small' onClick={ _handleClose }>
              close
            </Button>
          }
        />
      }
      <Button color='secondary' variant='contained' onClick={ () => history.goBack() }>
        Go Back
      </Button>
      <label className='csv-upload' htmlFor='csv-upload'>
        <Button variant='outlined' component='span' color='primary'>
          Upload CSV
        </Button>
      </label>
      <form onReset={ e => _handleClearPreview(e) } >
        <input
          id='csv-upload'
          type='file'
          accept='.csv'
          onChange={ e => _handleFileChange(e) }
          disabled={ !!preview.length }
          style={ { display: 'none' } }
        />
        {
          !!preview.length && showPreview &&
          <SimpleDialog preview={ preview } open={ showPreview } tableHeader={ tableHeader } onClose={ handleModalClose } handleSubmit={ _handleSubmitPreview } />
        }
      </form>
      <br /><br />
      {
        <MaterialTable
          title={ `Receipt ${ match.params.receiptNumber }` }
          columns={ [
            {
              title: 'Inventory Name', field: 'inventory_item_id', type: 'numeric', cellStyle: centerStyle,
              render: (row) => <span>{ _inventoryNames(row) }</span>,
              editComponent: column => (
                <Select
                  id='inventory-id-select'
                  value={ column.value || '' }
                  onChange={ e => column.onChange(e.target.value) }
                >
                  { inventoryNames.map(id => <MenuItem key={ id.id } value={ id.id }>{ id.inventory_name }</MenuItem>) }
                </Select>
              )
            },
            { title: 'Quantity Expected', field: 'quantity_expected', type: 'numeric', cellStyle: centerStyle,
              emptyValue: () => <span className="italic">N/A</span>
            },
            { title: 'Quantity Received', field: 'quantity_received', type: 'numeric', cellStyle: centerStyle,
              emptyValue: () => <span className="italic">N/A</span>
            },
            { title: 'Received At', field: 'received_at', type: 'datetime', cellStyle: centerStyle, 
              emptyValue: () => <span className="italic">N/A</span>
            },
            { title: 'Shipped At', field: 'shipped_at', type: 'datetime', cellStyle: centerStyle, 
              emptyValue: () => <span className="italic">N/A</span> 
            },
            { title: 'State', field: 'state', lookup: receiptLineStateMap, cellStyle: centerStyle },
            {
              title: 'Supplier', field: 'supplier_id', type: 'string', cellStyle: centerStyle, defaultSort: 'asc',
              customSort: (a, b) => _supplierNames(a) > _supplierNames(b) ? 1 : -1,
              render: (row) => <span>{ _supplierNames(row) }</span>,
              editComponent: column => (
                <Select
                  id='supplier-id-select'
                  value={ column.value || '' }
                  onChange={ e => column.onChange(e.target.value) }
                >
                  { suppliers.sort((a, b) => (a.name > b.name) ? 1 : -1).map(id => <MenuItem key={ id.id } value={ id.id }>{ id.name }</MenuItem>) }
                </Select>
              )
            },
            {
              title: 'Tracking', field: 'tracking_url', type: 'string', cellStyle: centerStyle,
              render: (row) => ( row.tracking_url && <Link href={ row.tracking_url } target="_blank">Tracking</Link> )
            }
          ] }
          data={ receiptLines }
          options={ {
            headerStyle: tableHeader,
            paging: false,
            draggable: false
          } }
          editable={ {
            onRowAdd: (newData) => _handleRowAdd(newData),
            onRowUpdate: (newData, oldData) => _handleRowUpdate(newData, oldData),
            onRowDelete: (oldData) => _handleRowDelete(oldData),
          } }
        />
      }
      <br /><br />
    </div>
  )
}