import React from 'react'
import { Link } from 'react-router-dom'
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  makeStyles
} from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    margin: '20px 20px 0 0',
  },
  container: {
    margin: '0 auto',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  media: {
    height: 200
  },
  content: {
    height: 'auto'
  }
})

export default function Home(props) {
  const classes = useStyles()

  return (
    <Box className={ classes.container }>
      <Link to="/allocator">
        <Card className={ classes.root }>
          <CardActionArea>
            <CardMedia
              className={ classes.media }
              image="https://res.cloudinary.com/roastcollective/image/upload/v1585677913/web/homepage/BigBoi_HP_Module_Desktop_IMG_2x.png"
              title="The Allocator Alligator"
            />
            <CardContent className={ classes.content }>
              <Typography gutterBottom variant="h5" component="h2">
                Allocator Tool
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Change next order date or next coffee variants by uploading a CSV to this bulk-update tool.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Link>

      <Link to="/deallocator">
        <Card className={ classes.root }>
          <CardActionArea>
            <CardMedia
              className={ classes.media }
              image="https://res.cloudinary.com/roastcollective/image/upload/v1585677913/web/homepage/BigBoi_HP_Module_Desktop_IMG_2x.png"
              title="The 'See Ya Later' Alligator"
              style={ { filter: 'invert(1)', transform: 'scaleX(-1)' } }
            />
            <CardContent className={ classes.content }>
              <Typography gutterBottom variant="h5" component="h2">
                Deallocator Tool
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Remove coffee variants from queues by uploading a CSV to this bulk-remove tool.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Link>

      <Link to="/suppliers">
        <Card className={ classes.root }>
          <CardActionArea>
            <CardMedia
              className={ classes.media }
              image="https://res.cloudinary.com/roastcollective/image/upload/v1573491132/web/homepage/packaging-coffee.jpg"
              title="Supplier Management"
            />
            <CardContent className={ classes.content }>
              <Typography gutterBottom variant="h5" component="h2">
                Supplier Management
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Set hard caps on Roasters and SKUs, or hide recos from the algo using this tool.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Link>

      <Link to="/receipts">
        <Card className={ classes.root }>
          <CardActionArea>
            <CardMedia
              className={ classes.media }
              image="https://res.cloudinary.com/roastcollective/image/upload/v1590163458/compostable-bag-cropped.png"
              title="Receipt Management"
            />
            <CardContent className={ classes.content }>
              <Typography gutterBottom variant="h5" component="h2">
                Receipt Management
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Add, edit, and delete Purchase Orders and Roaster replenishments from this page.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Link>

      <Link to="/barista">
        <Card className={ classes.root }>
          <CardActionArea>
            <CardMedia
              className={ classes.media }
              image="https://res.cloudinary.com/roastcollective/image/upload/v1580234261/web/homepage/ColdBagsnewhowto_1.png"
              title="Barista"
            />
            <CardContent className={ classes.content }>
              <Typography gutterBottom variant="h5" component="h2">
                Barista
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Triage upcoming coffee orders that might not be the best fit for users.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Link>
    </Box>
  )
}
