import React, { useState, useEffect } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import AllocatorTool from './components/AllocatorTool/AllocatorTool'
import AuthService from './services/AuthService'
import BaristaTool from './components/BaristaTool/BaristaTool'
import DeallocatorTool from './components/DeallocatorTool/DeallocatorTool'
import Home from './components/Home/Home'
import Login from './components/Login/Login'
import NavBar from './components/NavBar/NavBar'
import NotFound from './components/NotFound/NotFound'
import PrivateRoute from './components/PrivateRoute/PrivateRoute'
import ReceiptMgmt from './components/ReceiptMgmt/ReceiptMgmt'
import SupplierHandler from './components/Suppliers/SupplierHandler'
import './scss/App.scss'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { LinearProgress } from '@material-ui/core'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#f53c32'
    },
    secondary: {
      main: '#f53c32'
    },
    action: {
      active: '#f53c32',
    }
  },
  centerStyle: {
    textAlign: 'center',
  },
  tableHeader: {
    zIndex: 1,
    textTransform: 'capitalize',
    textAlign: 'center',
    fontWeight: 900,
  },
})

export default function App() {
  const [user, setUser] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    AuthService.getCurrentUser()
      .then(user => {
        if (user) {
          setUser(user)
        } else {
          setUser(null)
        }
        setIsLoading(false)
      })
  }, [])

  const renderCurrentView = () => {
    return (
      <Switch>
        <Route exact path="/login"><Login setUser={ setUser } /></Route>
        <PrivateRoute exact path="/" user={ user } component={ Home } />
        <PrivateRoute exact path="/allocator" user={ user } component={ AllocatorTool } />
        <PrivateRoute exact path="/deallocator" user={ user } component={ DeallocatorTool } />
        <PrivateRoute exact path="/barista" user={ user } component={ BaristaTool } />
        <PrivateRoute path="/suppliers" user={ user } component={ SupplierHandler } />
        <PrivateRoute path="/receipts" user={ user } component={ ReceiptMgmt } />
        <Route exact path="*" component={ NotFound } />
      </Switch >
    )
  }

  return (
    <BrowserRouter>
      <ThemeProvider theme={ theme }>
        <NavBar user={ user } setUser={ setUser } />
        { isLoading && <LinearProgress /> }
        <div className="codash__wrapper">
          { !isLoading && renderCurrentView() }
        </div>
      </ThemeProvider>
    </BrowserRouter>
  )
}
