import React from 'react'

export default function NotFound(props) {

  return (
    <div className="not-found__container">
      <h1 className="not-found__text">
        Oops.. looks like what you're looking for is not here :(
      </h1>
    </div>
  )
}