import React, { useState, useEffect } from 'react'
import { Link, Redirect, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import AuthService from '../../services/AuthService'
import BrightnessAutoIcon from '@material-ui/icons/BrightnessAuto'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import StorefrontIcon from '@material-ui/icons/Storefront'
import Button from '@material-ui/core/Button'
import Drawer from '@material-ui/core/Drawer'
import HomeIcon from '@material-ui/icons/Home'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import LocalCafeIcon from '@material-ui/icons/LocalCafe'
import MenuIcon from '@material-ui/icons/Menu'
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  }
}))

export default function NavBar(props) {
  const classes = useStyles()
  const location = useLocation()
  const [isOpen, setIsOpen] = useState(false)
  const [title, setTitle] = useState('Home')
  const [redirect, setRedirect] = useState('')

  const pathnameMap = {
    '/': { name: 'Home', icon: <HomeIcon /> },
    '/allocator': { name: 'Allocator', icon: <BrightnessAutoIcon /> },
    '/barista': { name: 'Barista', icon: <LocalCafeIcon /> },
    '/deallocator': { name: 'Deallocator', icon: <RemoveCircleIcon /> },
    '/receipts': { name: 'Receipts', icon: <MoveToInboxIcon /> },
    '/suppliers': { name: 'Suppliers', icon: <StorefrontIcon /> }
  }

  useEffect(() => {
    const pathObj = pathnameMap[location.pathname]
    const name = (pathObj && pathObj.name) || ''
    setTitle(name)
    setRedirect('')
  }, [location, redirect, pathnameMap])

  const toggleDrawer = value => setIsOpen(value)

  const setUser = (user) => props.setUser(user)

  const logout = () => {
    AuthService.logout()
    setUser(null)
    setRedirect('/login')
  }

  return (
    <div className={ classes.root }>
      {
        redirect && <Redirect to={ redirect } />
      }
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" className={ classes.menuButton } color="inherit" aria-label="menu" onClick={ () => { toggleDrawer(true) } }>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={ classes.title }>
            { title }
          </Typography>
          {
            (props.user && props.user.isCodashUser) ?
              <Button color="inherit" onClick={ logout }>Logout</Button> :
              <Link to={ '/login' }><Button color="inherit">Login</Button></Link>
          }
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={ isOpen }
        onClose={ () => { toggleDrawer(false) } }
      >
        <div className={ classes.list }>
          <List>
            {
              Object.keys(pathnameMap).map((path) => {
                const name = pathnameMap[path].name
                const icon = pathnameMap[path].icon

                return (
                  <Link to={ path } key={ name }>
                    <ListItem button onClick={ () => { toggleDrawer(false) } }>
                      <ListItemIcon>{ icon }</ListItemIcon>
                      <ListItemText primary={ name } />
                    </ListItem>
                  </Link>
                )
              })
            }
          </List>
        </div>
      </Drawer>
    </div>
  )
}
