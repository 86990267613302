import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import MaterialTable from 'material-table'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

export default function SupplierCaps(props) {
  const [snack, setSnack] = useState(null)
  const [supplierAttributes, setSupplierAttributes] = useState([])
  const [productAttributes, setProductAttributes] = useState([])
  const [productIds, setProductIds] = useState([])
  const orderEligibilityMap = { any: 'any', first: 'first order', nth: 'nth only' }
  const { supplier, tableHeader, centerStyle, match } = props
  let history = useHistory()

  useEffect(() => {
    const getAttributes = async () => {
      const path = `/api${ match.url }/attributes`
      const response = await fetch(path)
      if (response.status !== 200) throw Error(response.json().body)
      return response.json()
    }

    const getProductIds = async () => {
      const path = `/api${ match.url }/productIds`
      const response = await fetch(path)
      if (response.status !== 200) throw Error(response.json().body)
      return response.json()
    }

    getAttributes()
      .then((attributes) => {
        const { supplier_attribute, product_attributes } = attributes
        if (supplier_attribute) setSupplierAttributes([supplier_attribute])
        if (product_attributes) setProductAttributes(product_attributes)
      })

    getProductIds()
      .then((jsonResponse) => {
        const responseIds = jsonResponse.products
        if (responseIds) setProductIds(responseIds)
      })
  }, [match.url])

  const createAttribute = async (newData) => {
    const path = `/api${ match.url }/attributes`
    const body = newData
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    }

    const response = await fetch(path, options)

    if (response.status !== 200) throw Error(response.json().body)

    return response.json()
  }

  const updateAttribute = async (newData) => {
    const path = `/api${ match.url }/attributes/${ newData.id }`
    const body = newData
    const options = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    }

    const response = await fetch(path, options)

    if (response.status !== 200) throw Error(response.json().body)

    return response.json()
  }

  const deleteAttribute = async (oldData) => {
    const path = `/api${ match.url }/attributes/${ oldData.id }`
    const options = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' }
    }

    const response = await fetch(path, options)

    if (response.status !== 200) throw Error(response.json().body)

    return response.json()
  }

  const _handleRowAdd = async (newData, recommendableType) => {
    try {
      newData.recommendable_type = recommendableType
      const responseJson = await createAttribute(newData)
      const attribute = responseJson.attribute

      if (attribute) {
        if (attribute.recommendable_type === 'supplier') {
          setSupplierAttributes((prevState) => {
            const data = [...prevState]
            data.push(attribute)
            return data
          })
        } else if (attribute.recommendable_type === 'product') {
          setProductAttributes((prevState) => {
            const data = [...prevState]
            data.push(attribute)
            return data
          })
        }
      } else if (responseJson.error) {
        setSnack(`Error: ${ responseJson.error }`)
      }
    } catch (e) {
      // TODO: Honeybadger log it
      console.log(e)
      setSnack('Something went wrong :(')
    }
  }

  const _handleRowUpdate = async (newData, oldData) => {
    try {
      const responseJson = await updateAttribute(newData)
      const attribute = responseJson.attribute

      if (attribute) {
        if (attribute.recommendable_type === 'supplier') {
          setSupplierAttributes((prevState) => {
            const data = [...prevState]
            data[data.indexOf(oldData)] = attribute
            return data
          })
        } else if (attribute.recommendable_type === 'product') {
          setProductAttributes((prevState) => {
            const data = [...prevState]
            data[data.indexOf(oldData)] = attribute
            return data
          })
        }
      } else if (responseJson.error) {
        setSnack(`Error: ${ responseJson.error }`)
      }
    } catch (e) {
      // TODO: Honeybadger log it
      console.log({ e })
      setSnack('Something went wrong :(')
    }
  }

  const _handleRowDelete = async (oldData) => {
    try {
      const responseJson = await deleteAttribute(oldData)
      const attribute = responseJson.attribute

      if (attribute) {
        if (attribute.recommendable_type === 'supplier') {
          setSupplierAttributes((prevState) => {
            const data = [...prevState]
            data.splice(data.indexOf(oldData), 1)
            return data
          })
        } else if (attribute.recommendable_type === 'product') {
          setProductAttributes((prevState) => {
            const data = [...prevState]
            data.splice(data.indexOf(oldData), 1)
            return data
          })
        }
      } else if (responseJson.error) {
        setSnack(`Error: ${ responseJson.error }`)
      }
    } catch (e) {
      // TODO: Honeybadger log it
      console.log({ e })
      setSnack('Something went wrong :(')
    }
  }

  const _handleClose = (event, reason) => {
    if (reason === 'clickaway') return
    setSnack(null)
  }

  return (
    <div className='table__container'>
      { snack &&
        <Snackbar
          anchorOrigin={ {
            vertical: 'bottom',
            horizontal: 'left',
          } }
          open={ snack ? true : false }
          autoHideDuration={ 6000 }
          onClose={ _handleClose }
          message={ snack }
          action={
            <>
              <Button color="secondary" size="small" onClick={ _handleClose }>
                close
              </Button>
            </>
          }
        />
      }
      <Button color="secondary" variant="contained" onClick={ () => history.goBack() }>
        Go Back
      </Button>
      <br /><br />
      {
        <MaterialTable
          title={ supplier.name }
          columns={ [
            { title: 'Recommendation Eligible', field: 'recommendation_eligible', type: 'boolean', cellStyle: centerStyle },
            { title: 'Order Eligibility', field: 'order_eligibility', lookup: orderEligibilityMap, cellStyle: centerStyle },
            { title: 'Monday Cap', field: 'monday_volume_cap', type: 'numeric', cellStyle: centerStyle },
            { title: 'Tuesday Cap', field: 'tuesday_volume_cap', type: 'numeric', cellStyle: centerStyle },
            { title: 'Wednesday Cap', field: 'wednesday_volume_cap', type: 'numeric', cellStyle: centerStyle },
            { title: 'Thursday Cap', field: 'thursday_volume_cap', type: 'numeric', cellStyle: centerStyle },
            { title: 'Friday Cap', field: 'friday_volume_cap', type: 'numeric', cellStyle: centerStyle },
            { title: 'Saturday Cap', field: 'saturday_volume_cap', type: 'numeric', cellStyle: centerStyle },
            { title: 'Sunday Cap', field: 'sunday_volume_cap', type: 'numeric', cellStyle: centerStyle }
          ] }
          data={ supplierAttributes }
          options={ {
            headerStyle: tableHeader,
            paging: false,
            search: false,
            sorting: false,
            draggable: false,
            actionsCellStyle: {
              minWidth: '100px',
            }
          } }
          editable={ {
            onRowAdd: (supplierAttributes.length) ? null : (newData) => _handleRowAdd(newData, 'supplier'),
            onRowUpdate: (newData, oldData) => _handleRowUpdate(newData, oldData),
          } }
        />
      }
      <br /><br />
      {
        <MaterialTable
          title={ 'Product Caps' }
          columns={ [
            {
              title: 'ID', field: 'recommendable_id', type: 'numeric', cellStyle: centerStyle, editable: 'onAdd', editComponent: column => (
                <Select
                  id="product-id-select"
                  value={ column.value || '' }
                  onChange={ e => column.onChange(e.target.value) }
                >
                  {
                    productIds.map(id => <MenuItem key={ id } value={ id }>{ id }</MenuItem>)
                  }
                </Select>
              )
            },
            { title: 'SKU', field: 'recommendable_sku', cellStyle: centerStyle, editable: 'never' },
            { title: 'Name', field: 'recommendable_name', cellStyle: centerStyle, editable: 'never' },
            { title: 'Recommendation Eligible', field: 'recommendation_eligible', type: 'boolean', cellStyle: centerStyle },
            { title: 'Order Eligibility', field: 'order_eligibility', lookup: orderEligibilityMap, cellStyle: centerStyle },
            { title: 'Monday Cap', field: 'monday_volume_cap', type: 'numeric', cellStyle: centerStyle },
            { title: 'Tuesday Cap', field: 'tuesday_volume_cap', type: 'numeric', cellStyle: centerStyle },
            { title: 'Wednesday Cap', field: 'wednesday_volume_cap', type: 'numeric', cellStyle: centerStyle },
            { title: 'Thursday Cap', field: 'thursday_volume_cap', type: 'numeric', cellStyle: centerStyle },
            { title: 'Friday Cap', field: 'friday_volume_cap', type: 'numeric', cellStyle: centerStyle },
            { title: 'Saturday Cap', field: 'saturday_volume_cap', type: 'numeric', cellStyle: centerStyle },
            { title: 'Sunday Cap', field: 'sunday_volume_cap', type: 'numeric', cellStyle: centerStyle }
          ] }
          data={ productAttributes }
          options={ {
            headerStyle: tableHeader,
            paging: false,
            sorting: false,
            draggable: false
          } }
          editable={ {
            onRowAdd: (newData) => _handleRowAdd(newData, 'product'),
            onRowUpdate: (newData, oldData) => _handleRowUpdate(newData, oldData),
            onRowDelete: (oldData) => _handleRowDelete(oldData),
          } }
        />
      }
      <br /><br />
    </div>
  )
}