import React, { useState, useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import SupplierList from './SupplierList'
import SupplierCaps from './SupplierCaps'
import NotFound from '../NotFound/NotFound'
import { useTheme } from '@material-ui/core/styles'

export default function SupplierHandler({ match }) {
  const [suppliers, setSuppliers] = useState([])
  const theme = useTheme()

  useEffect(() => {
    async function getSuppliers() {
      const path = '/api/suppliers'
      const response = await fetch(path)
      const responseJson = await response.json()

      if (response.status !== 200) throw Error(responseJson)

      return responseJson
    }

    getSuppliers()
      .then((responseJson) => {
        const responseSuppliers = responseJson.suppliers
        if (responseSuppliers && responseSuppliers.length) setSuppliers(responseSuppliers)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  return (
    <>
      { suppliers.length > 0 &&
        <Switch>
          <Route
            exact path={ match.path }
            render={ (props) => {
              return <SupplierList { ...props } suppliers={ suppliers } tableHeader={ theme.tableHeader } centerStyle={ theme.centerStyle } />
            } }
          />
          <Route
            path={ `${ match.path }/:supplier_slug` }
            render={ (props) => {
              const supplier = suppliers.find(supplier => supplier.slug === props.match.params.supplier_slug)
              if (!supplier) return <NotFound { ...props } />
              return <SupplierCaps { ...props } supplier={ supplier } tableHeader={ theme.tableHeader } centerStyle={ theme.centerStyle } />
            }
            } />
        </Switch>
      }
    </>
  )
}

