import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Drawer,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
  DialogTitle,
  IconButton,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import { Close, ExpandMore } from '@material-ui/icons'

import SubscriptionTypeChip from './SubscriptionTypeChip'
import TastyChips from './TastyChips'
import CoffeeCard from './CoffeeCard'
import CoffeeSelector from './CoffeeSelector'
import CoffeeTimeline from './CoffeeTimeline'
import QuizAnswers from './QuizAnswers'

import './EditDrawer.scss'

const EditDrawer = ({ isVisible, handleOnClose, handleTasteGroupsChanged, handleCoffeeSelected, data, changes }) => {
  const [showCoffeeSelector, setShowCoffeeSelector] = useState(false)
  const [editTasteGroups, setEditTasteGroups] = useState(false)
  const [shouldSendEmail, setShouldSendEmail] = useState(false)
  const [nextCoffee, setNextCoffee] = useState(data.next_coffee)
  const [allTasteGroups, setAllTasteGroups] = useState([])

  const loadAllTasteGroups = async () => {
    const response = await fetch('/api/metadata/taste_groups', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })

    if (response.status !== 200) throw Error(response.json().body)

    return response.json()
  }

  useEffect(() => {
    if (isVisible && allTasteGroups.length === 0) {
      loadAllTasteGroups()
        .then(responseJson => {
          const allTasteGroups = responseJson.taste_groups

          if (allTasteGroups && allTasteGroups.length > 0) {
            setAllTasteGroups(allTasteGroups)
          }
        })
    }

    if (data && data.next_coffee) {
      setNextCoffee(data.next_coffee)
    }

    if (changes && changes.shouldSendEmail) {
      setShouldSendEmail(changes.shouldSendEmail)
    }

  }, [isVisible, data, changes])

  const { user, taste_groups: tasteGroups } = data

  const handleOnCloseInternal = () => {
    setShowCoffeeSelector(false)
    setEditTasteGroups(false)
    handleOnClose()
  }
  const handleShouldSendEmailChange = (event) => {
    setShouldSendEmail(event.target.checked)
  }

  const fetchProduct = async (productId) => {
    const path = `/api/products/${ productId }`
    const response = await fetch(path)
    if (response.status !== 200) throw Error(response.json().body)
    return response.json()
  }

  return (
    isVisible &&
    <div>
      <Drawer open={ true } onClose={ handleOnCloseInternal } anchor={ 'right' }>
        <div className="edit-drawer__title">
          <DialogTitle disableTypography className="drawerTitle">
            <IconButton onClick={ handleOnCloseInternal }>
              <Close />
            </IconButton>
          </DialogTitle>
        </div>
        <div className="edit-drawer">
          <div className="edit-drawer__header">
            <SubscriptionTypeChip subscriptionType={ data.subscription_type } />
            <p className="edit-drawer__header-link">
              <Link href={ `https://app.drinktrade.com/admin/users/${ user.user_id }/edit` } target="_blank">{ user.email }</Link>
            </p>
            {
              !editTasteGroups &&
              <TastyChips
                tasteGroups={ tasteGroups }
                allTasteGroups={ allTasteGroups }
                handleOnSave={ handleTasteGroupsChanged }
                handleOnError={ (err) => console.log(err) }
                isEditable={ true } />
            }
          </div>
          <div className="edit-drawer__body">
            <h4>Next Coffee</h4>
            <CoffeeCard product={ nextCoffee }>
              {
                showCoffeeSelector &&
                <div className="coffee-selector__container">
                  <CoffeeSelector
                    handleValueSelected={ (productObj) => {
                      if (productObj && productObj.object_id) {
                        fetchProduct(productObj.object_id)
                          .then((product) => {
                            const variants = product.variants

                            if (variants && variants.length) {
                              setShowCoffeeSelector(false)
                              setNextCoffee(product)
                              handleCoffeeSelected(product, shouldSendEmail)
                            }
                          })
                      }
                    } }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={ shouldSendEmail }
                        onChange={ (event) => handleShouldSendEmailChange(event) }
                      />
                    }
                    label="Send Email?"
                    labelPlacement="start"
                  />
                  {/* <Button color="primary" onClick={ () => { setShowCoffeeSelector(!showCoffeeSelector) } }>
                    Close
                  </Button> */}
                </div>
              }
              {
                !showCoffeeSelector &&
                <Button variant="contained" color="primary" onClick={ () => { setShowCoffeeSelector(!showCoffeeSelector) } }>
                  Change
                </Button>
              }
            </CoffeeCard>
            <div className="edit-drawer__menus">
              {
                user.quiz_answers &&
                <Accordion>
                  <AccordionSummary expandIcon={ <ExpandMore /> }>
                    <h2>Quiz Answers</h2>
                  </AccordionSummary>
                  <AccordionDetails>
                    <QuizAnswers quizAnswers={ user.quiz_answers } />
                  </AccordionDetails>
                </Accordion>
              }
              {
                user.rating_history &&
                <Accordion>
                  <AccordionSummary expandIcon={ <ExpandMore /> }>
                    <h2>Coffee History</h2>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CoffeeTimeline ratings={ user.rating_history } />
                  </AccordionDetails>
                </Accordion>
              }
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  )
}

EditDrawer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  data: PropTypes.object,
  changes: PropTypes.object,
  handleTasteGroupsChanged: PropTypes.func.isRequired,
  handleCoffeeSelected: PropTypes.func.isRequired,
  handleOnClose: PropTypes.func.isRequired
}

export default EditDrawer
