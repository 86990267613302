import React, { useState, useEffect } from 'react'
import { Switch, Link, Route } from 'react-router-dom'
import MaterialTable from 'material-table'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import { useTheme } from '@material-ui/core/styles'
import ReceiptLines from './ReceiptLines'
import NotFound from '../NotFound/NotFound'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'


export default function ReceiptMgmt(props) {
  const { match } = props
  const theme = useTheme()
  const [snack, setSnack] = useState(null)
  const [receipts, setReceipts] = useState([])
  const [receiptStates, setReceiptStates] = useState({})

  const receiptTypeMap = {
    PurchaseOrder: 'Purchase Order',
    Transfer: 'Transfer',
    Adjustment: 'Adjustment'
  }

  const receiptStateMap = {
    created: 'Created',
    in_transit: 'In Transit',
    received: 'Received',
    partially_received: 'Partially Received',
    canceled: 'Canceled'
  }

  useEffect(() => {
    const getReceipts = async () => {
      const path = '/api/receipts'
      const response = await fetch(path)
      if (response.status !== 200) throw Error(response.json().body)
      return response.json()
    }

    const getStates = async () => {
      const path = `/api/receipts/states`
      const response = await fetch(path)
      if (response.status !== 200) throw Error(response.json().body)
      return response.json()
    }

    getReceipts()
      .then((responseJson) => {
        const responseReceipts = responseJson.receipts
        if (responseReceipts && responseReceipts.length) {
          const sortedReceipts = responseReceipts.sort((a, b) => a.created_at < b.created_at ? 1 : -1)
          setReceipts(sortedReceipts)
        }
      })

    getStates()
      .then((jsonResponse) => {
        const states = jsonResponse.states
        if (states) setReceiptStates(states)
      })
  }, [])

  const _handleClose = (event, reason) => {
    if (reason === 'clickaway') return
    setSnack(null)
  }

  const createReceipt = async (newData) => {
    const path = `/api/receipts`
    const body = newData
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    }

    const response = await fetch(path, options)
    if (response.status !== 200) throw Error(response.json().body)
    return response.json()
  }

  const updateReceipt = async (newData) => {
    const path = `/api/receipts/${ newData.number }`
    const body = newData
    const options = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    }

    const response = await fetch(path, options)
    if (response.status !== 200) throw Error(response.json().body)
    return response.json()
  }

  const deleteReceipt = async (oldData) => {
    const path = `/api/receipts/${ oldData.number }`
    const options = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' }
    }

    const response = await fetch(path, options)
    if (response.status !== 200) throw Error(response.json().body)
    return response.json()
  }

  const _handleRowAdd = async (newData) => {
    try {
      const responseJson = await createReceipt(newData)
      const receipt = responseJson.receipt

      if (receipt) {
        setReceipts((prevState) => {
          const data = [...prevState]
          data.push(receipt)
          return data
        })
      } else if (responseJson.error) {
        setSnack(`Error: ${ responseJson.error }`)
      }
    } catch (e) {
      // TODO: Honeybadger log it
      console.log(e)
      setSnack('Something went wrong :(')
    }
  }

  const _handleRowUpdate = async (newData, oldData) => {
    try {
      const responseJson = await updateReceipt(newData)
      const receipt = responseJson.receipt
      if (receipt) {
        setReceipts((prevState) => {
          const data = [...prevState]
          data[data.indexOf(oldData)] = receipt
          return data
        })
      } else if (responseJson.error) {
        setSnack(`Error: ${ responseJson.error }`)
      }
    } catch (e) {
      // TODO: Honeybadger log it
      console.log({ e })
      setSnack('Something went wrong :(')
    }
  }

  const _handleRowDelete = async (oldData) => {
    try {
      const responseJson = await deleteReceipt(oldData)
      const receipt = responseJson.receipt

      if (receipt) {
        setReceipts((prevState) => {
          const data = [...prevState]
          data.splice(data.indexOf(oldData), 1)
          return data
        })
      } else if (responseJson.error) {
        setSnack(`Error: ${ responseJson.error }`)
      }
    } catch (e) {
      // TODO: Honeybadger log it
      console.log({ e })
      setSnack('Something went wrong :(')
    }
  }


  return (
    <Switch>
      <Route
        exact path={ match.path }
        render={ (props) => {
          return (
            <>
              { snack &&
                <Snackbar
                  anchorOrigin={ {
                    vertical: 'bottom',
                    horizontal: 'left',
                  } }
                  open={ snack ? true : false }
                  autoHideDuration={ 6000 }
                  onClose={ _handleClose }
                  message={ snack }
                  action={
                    <>
                      <Button color="secondary" size="small" onClick={ _handleClose }>
                        close
                      </Button>
                    </>
                  }
                />
              }
              <br /><br />
              <div className='table__container'>
                <MaterialTable
                  columns={
                    [
                      { title: 'Order Name', field: "external_id", type: "string", cellStyle: theme.centerStyle },
                      {
                        title: 'Number',
                        field: 'number',
                        type: 'string',
                        cellStyle: theme.centerStyle,
                        render: row => <Link to={ { pathname: `${ match.url }/${ row.number }` } }><u>{ row.number }</u></Link>
                      },
                      { title: 'Type', field: 'receipt_type', lookup: receiptTypeMap, cellStyle: theme.centerStyle },
                      { title: 'Vendor', field: 'vendor', type: 'string', cellStyle: theme.centerStyle },
                      {
                        title: 'Status', field: 'state', lookup: receiptStateMap, cellStyle: theme.centerStyle,
                        editComponent: column => (
                          <Select
                            id="status-select"
                            value={ column.value || '' }
                            onChange={ e => column.onChange(e.target.value) }
                          >
                            { receiptStates.map((state) => <MenuItem key={ state } value={ state }>{ receiptStateMap[state] }</MenuItem>) }
                          </Select>
                        )
                      },
                      { title: 'Created On', field: 'created_at', type: 'datetime', cellStyle: theme.centerStyle }
                    ]
                  }
                  data={ receipts }
                  title=''
                  options={ {
                    headerStyle: theme.tableHeader,
                    paging: false,
                    sorting: true
                  } }
                  editable={ {
                    onRowAdd: (newData) => _handleRowAdd(newData),
                    onRowUpdate: (newData, oldData) => _handleRowUpdate(newData, oldData),
                    onRowDelete: (oldData) => _handleRowDelete(oldData),
                  } }
                />
              </div>
            </>
          )
        } }
      />
      <Route
        path={ `${ match.path }/:receiptNumber` }
        render={ (props) => {
          const receipt = receipts.find(r => r.number === props.match.params.receiptNumber)
          if (!receipt) return <NotFound { ...props } />
          return <ReceiptLines { ...props } tableHeader={ theme.tableHeader } goBack={ theme.goBack } centerStyle={ theme.centerStyle } />
        }
        } />
    </Switch>
  )
}

