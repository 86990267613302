import React from 'react'
import PropTypes from 'prop-types'

import { Chip } from '@material-ui/core'

const TASTE_GROUP_COLORS = {
  '1': '#FBC02D',
  '2': '#FF4081',
  '3': '#607D8B',
  '4': '#0097A7',
  '6': '#1de9b6',
  '7': '#FF5252',
  '8': '#FF9800',
  '10': '#536DFE',
  '11': '#4CAF50',
  '12': '#3F51B5',
  '13': '#512DA8',
  '14': '#9C27B0',
}

const TastyChip = ({ tasteGroup, handleOnDelete }) => {
  const resolveOnDelete = () => {
    if (!handleOnDelete) { return undefined }

    return () => { handleOnDelete(tasteGroup) }
  }
  
  return (
    <Chip
      variant="outlined"
      size="small"
      label={ tasteGroup.description || tasteGroup.display }
      style={ {
        fontSize: '12px',
        backgroundColor: TASTE_GROUP_COLORS[tasteGroup.name],
        color: 'white'
      } }
      onDelete={  resolveOnDelete() }
    />
  )
}

TastyChip.propTypes = {
  tasteGroup: PropTypes.object.isRequired,
  handleOnDelete: PropTypes.func
}

export default TastyChip