import React from "react"
import { Route, Redirect } from "react-router-dom"

/**
 * This function is a wrapper for <Route> that redirects to
 * the login screen if user is not yet authenticated.
 * See: https://reactrouter.com/web/example/auth-workflow
 */
export default function PrivateRoute({ component: Component, user, ...otherProps }) {
  return (
    <Route
      { ...otherProps }
      render={ (props) => {
        return (user && user.isCodashUser) ?
          <Component { ...props } /> :
          <Redirect to='/login' />
      } }
    />
  )
}
