import React, { useState } from 'react'
import clsx from 'clsx'
import { useHistory, useLocation } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput
} from '@material-ui/core'
import AuthService from '../../services/AuthService'
import './Login.scss'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '32ch',
  },
}))

export default function Login(props) {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()

  const [values, setValues] = useState({
    email: '',
    password: '',
    showPassword: false,
    rememberMe: false
  })

  const setUser = (user) => props.setUser(user)

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleClickRememberMe = () => {
    setValues({ ...values, rememberMe: !values.rememberMe })
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const validateValues = () => {
    return values.email && values.email.length && values.password && values.password.length
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!validateValues()) {
      handleFail('Email and Password cannot be blank')
      return
    }

    const params = {
      email: values.email,
      password: values.password,
      rememberMe: values.rememberMe
    }

    try {
      const responseJson = await AuthService.login(params)

      setValues({ ...values, email: '', password: '' })
      setUser(responseJson)

      const { from } = location.state || { from: { pathname: '/' } }

      history.replace(from)
    } catch (e) {
      handleFail(e.message)
    }
  }

  const handleFail = (errorMessage) => {
    // TODO: display error message, highlight red
    console.log('Login failed! Message:', errorMessage)
  }

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={ (e) => handleSubmit(e) }>
        <FormControl className={ clsx(classes.margin, classes.textField) } variant="outlined">
          <InputLabel htmlFor="email">Email</InputLabel>
          <OutlinedInput
            id="email"
            type="text"
            value={ values.email }
            onChange={ handleChange('email') }
            label="email"
          />
        </FormControl>

        <FormControl className={ clsx(classes.margin, classes.textField) } variant="outlined">
          <InputLabel htmlFor="password">Password</InputLabel>
          <OutlinedInput
            id="password"
            type={ values.showPassword ? 'text' : 'password' }
            value={ values.password }
            onChange={ handleChange('password') }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  style={ { backgroundColor: 'white' } }
                  aria-label="toggle password visibility"
                  onClick={ handleClickShowPassword }
                  onMouseDown={ handleMouseDownPassword }
                  edge="end"
                >
                  { values.showPassword ? <Visibility /> : <VisibilityOff /> }
                </IconButton>
              </InputAdornment>
            }
            label="password"
          />
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={ values.rememberMe }
              onChange={ handleClickRememberMe }
              name="rememberMe"
              color="primary"
            />
          }
          label="Stay logged in"
        />
        <Button type="submit" variant="contained" color="primary">Login</Button>
      </form>
    </div>
  )
}
