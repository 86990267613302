import React from 'react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'

const CoffeeTimelineEvent = ({ rating }) => {
  const formatDate = (d) => {
    let date = new Date(d)

    const orderDateTime = date.getTime() // in UTC
    const timezoneOffset = date.getTimezoneOffset() * 60 * 1000
    date.setTime(orderDateTime + timezoneOffset) // set orderDate to local

    return format(date, 'M/d/yyyy')
  }

  const renderComments = () => (
    <ul>
      {
        rating.product_feedbacks.length ? <li>{ rating.product_feedbacks.map(fb => fb.feedback_choice).join(', ') }</li> : null
      }
      {
        rating.general_comments ? <li className="general-comments">{ rating.general_comments }</li> : null
      }
    </ul>
  )

  const isPositive = rating.thumbs_up;

  return (
    <li>
      <div className="coffee-timeline__event-date">
        { formatDate(rating.created_at) }
      </div>
      <div className="coffee-timeline__event-text">
        <h3 className={`${!isPositive ? 'negative' : ''}`}>
          {`${ rating.product_brand_name } ${ rating.product_name } ${ isPositive ? '👍' : '👎' } `}
        </h3>
        { renderComments() }
      </div>
    </li>
  )
}


CoffeeTimelineEvent.propTypes = {
  rating: PropTypes.object.isRequired
}

export default CoffeeTimelineEvent