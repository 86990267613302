import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import './CoffeeSelector.scss'

const CoffeeSelector = ({ handleValueSelected }) => {
  const [isOpen, setIsOpen] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [catalog, setCatalog] = useState([])
  const [value, setValue] = useState('')
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    let active = true

    const fetchSearchResults = async (searchQuery) => {
      const path = '/api/products/search'
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ searchQuery })
      }

      const response = await fetch(path, options)
      if (response.status !== 200) throw Error(response.json().body)
      return response.json()
    }

    if (inputValue.length < 3) return undefined

    setIsLoading(true)

    try {
      fetchSearchResults(inputValue)
        .then((responseJson) => {
          if (active) {
            let responseCatalog = []

            if (responseJson.results && responseJson.results.length) {
              const products = responseJson.results.find(product => product.name === 'Product')
              if (products) responseCatalog = products.results
            }

            setCatalog(responseCatalog)
            setIsLoading(false)
          }
        })
    } catch (e) {
      setIsLoading(false)
      console.log('Error:', e)
    }

    return () => { active = false }
  }, [inputValue])

  useEffect(() => {
    handleValueSelected(value)
  }, [value, handleValueSelected])

  useEffect(() => {
    if (!isOpen) setCatalog([])
  }, [isOpen])

  const renderImage = (url) => (
    <img src={ url } alt='coffee-bag' style={ { width: '100px' } } />
  )

  const renderCatalogOption = (option) => {
    return (
      <div className="coffee-select-wrapper">
        <span className="coffee-img">
          { renderImage(option.default_image.url) }
        </span>
        <div className="product-display-info">
          <span className="bold">{ option.brand.name }</span>
          <span>{ option.name }</span>
        </div>
      </div>
    )
  }

  const renderInput = (params) => {
    return (
      <TextField
        { ...params }
        label="Search for coffee"
        variant="standard"
        color="primary"
        autoFocus={ true }
        InputProps={ {
          ...params.InputProps,
          endAdornment: (
            <>
              { isLoading ? <CircularProgress color="inherit" size={ 20 } /> : null }
              { params.InputProps.endAdornment }
            </>
          ),
        } }
      />
    )
  }

  return (
    <Autocomplete
      className="coffee-search-autocomplete"
      selectOnFocus
      open={ isOpen }
      loading={ isLoading }
      onOpen={ () => setIsOpen(true) }
      onClose={ () => setIsOpen(false) }
      onChange={ (e, newValue) => setValue(newValue) }
      onInputChange={ (e, newInputValue) => setInputValue(newInputValue) }
      options={ catalog }
      getOptionLabel={ (option) => option.name }
      getOptionSelected={ (option, value) => option.name === value.name }
      noOptionsText={ inputValue.length >= 3 ? 'No coffee found' : 'Please enter 3 or more characters' }
      renderOption={ (option) => renderCatalogOption(option) }
      renderInput={ (params) => renderInput(params) }
    />
  )
}

CoffeeSelector.propTypes = {
  handleValueSelected: PropTypes.func.isRequired
}

export default CoffeeSelector
