import React from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  Button
} from '@material-ui/core'

const SaveModal = ({ isOpen, handleClose, handleSave }) => (
  <Dialog aria-labelledby="simple-dialog-title" onClose={ handleClose } open={ isOpen } maxWidth={ 'md' }>
    <div className="coffee-select-container verify">
      <h1>Are you sure you want to save changes and dismiss this review?</h1>
      <div className="dialog-btn-wrapper">
        <Button onClick={ handleSave } variant="contained" color="primary">
          Save & Dismiss
        </Button>
        <Button onClick={ handleClose } variant="outlined" color="primary">
          No
        </Button>
      </div>
    </div>
  </Dialog>
)

SaveModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired
}

export default SaveModal
