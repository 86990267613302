import React from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  Button
} from '@material-ui/core'

const DismissModal = ({ isOpen, handleClose, handleDismiss }) => (
  <Dialog aria-labelledby="simple-dialog-title" onClose={ handleClose } open={ isOpen } maxWidth={ 'md' }>
    <div className="coffee-select-container verify">
      <h1>Are you sure you want to dismiss this review?</h1>
      <div className="dialog-btn-wrapper">
        <Button onClick={ handleDismiss } variant="contained" color="primary">
          Yes
        </Button>
        <Button onClick={ handleClose } variant="outlined" color="primary">
          No
        </Button>
      </div>
    </div>
  </Dialog>
)

DismissModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDismiss: PropTypes.func.isRequired
}

export default DismissModal
