import React from 'react'
import PropTypes from 'prop-types'
import { Chip } from '@material-ui/core'

const SubscriptionTypeChip = ({ subscriptionType }) => (
  <Chip
    key={ subscriptionType }
    size='small'
    label={ subscriptionType === 'CC' ? 'Classics' : 'Hookup' }
    variant="outlined"
    style={ {
      fontSize: '14px',
      // backgroundColor: '#ebebeb',
      color: '#000'
    } }
  />
)

export default SubscriptionTypeChip

SubscriptionTypeChip.propTypes = {
  subscriptionType: PropTypes.string.isRequired
}