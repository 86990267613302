import React from 'react'
import PropTypes from 'prop-types'
import QuizAnswer from './QuizAnswer'
import './QuizAnswers.scss'

const QuizAnswers = ({ quizAnswers }) => {
  let mappedQuestions = []

  for (const question in quizAnswers) {
    mappedQuestions.push({
      question,
      answer: quizAnswers[question]
    })
  }

  return (
    <div className="quiz-answers">
      <ul>
        {
          mappedQuestions.map((question) => (
            <QuizAnswer key={`${question.question}-${question.answer}`} question={ question.question } answer={ question.answer } />
          ))
        }
      </ul>
    </div>
  )
}

QuizAnswers.propTypes = {
  quizAnswers: PropTypes.object.isRequired
}

export default QuizAnswers