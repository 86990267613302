import React from 'react'
import PropTypes from 'prop-types'

import CoffeeTimelineEvent from './CoffeeTimelineEvent'

import './CoffeeTimeline.scss'

const CoffeeTimeline = ({ ratings }) => {
  return (
    <ul className="coffee-timeline">
      { ratings.map(rating => (
        <CoffeeTimelineEvent key={ rating.id } rating={ rating } />
      ))}
    </ul>
  )
}

CoffeeTimeline.propTypes = {
  ratings: PropTypes.array.isRequired
}

export default CoffeeTimeline