import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Select } from '@material-ui/core'
import TastyChip from './TastyChip'
import './TastyChips.scss'

const TastyChips = ({ tasteGroups, allTasteGroups, handleOnSave, handleOnError, isEditable }) => {
  // @todo - remove this after the api entities are cleaned up. underlying issue
  // is that depending on where the taste type comes from the id field might
  // be named differently.
  const mapTasteGroups = (tgs) => (
    tgs.map(tg => (
      {
        id: tg.taste_group_id ? tg.taste_group_id : tg.id,
        name: tg.name,
        description: tg.description
      }
    ))
  )

  const [isEditMode, setIsEditMode] = useState(false)
  const [selectedTasteGroups, setSelectedTasteGroups] = useState(mapTasteGroups(tasteGroups))
  const isEditing = !!(isEditable && isEditMode)

  const toggleEditMode = () => { setIsEditMode(!isEditMode) }

  const handleOnDelete = (tasteGroupToDelete) => {
    if (selectedTasteGroups.length <= 1) {
      handleOnError("You must select at least one taste group.")
    } else {
      setSelectedTasteGroups(
        selectedTasteGroups.filter(tasteGroup => tasteGroup.id !== tasteGroupToDelete.id)
      )
    }
  }

  const handleTasteGroupsChange = (event) => {
    const id = Number(event.target.value)
    const selectedTasteGroup = allTasteGroups.find(tg => tg.id === id)

    setSelectedTasteGroups(selectedTasteGroups.concat([selectedTasteGroup]))
  }

  const handleDoneClick = () => {
    toggleEditMode()
    handleOnSave(selectedTasteGroups)
  }

  const renderTasteGroupOptions = () => {
    const selectedIds = selectedTasteGroups.map(tg => tg.id)

    return allTasteGroups.filter(tg => !selectedIds.includes(tg.id))
      .map(tg => (
        <option key={ tg.id } value={ tg.id }>{ tg.description }</option>
      ))
  }

  return (
    <div className="tasty-chips">
      {
        (isEditable ? selectedTasteGroups : mapTasteGroups(tasteGroups)).map(tasteGroup => (
          <TastyChip
            key={ tasteGroup.id }
            tasteGroup={ tasteGroup }
            handleOnDelete={ isEditing ? handleOnDelete : undefined }
          />
        ))
      }
      {
        isEditable && !isEditMode &&
        <Button onClick={ toggleEditMode } color="secondary">Edit</Button>
      }
      {
        isEditing &&
        <>
          <Select
            native
            value={ '' }
            onChange={ handleTasteGroupsChange }
          >
            <option aria-label="None" value="" />
            { renderTasteGroupOptions() }
          </Select>

          <Button onClick={ handleDoneClick } color="secondary">Done</Button>
        </>
      }
    </div>
  )
}

TastyChips.propTypes = {
  tasteGroups: PropTypes.array.isRequired,
  isEditable: PropTypes.bool,
  handleOnSave: PropTypes.func,
  handleOnDelete: PropTypes.func
}

export default TastyChips
