import React, { useState } from 'react'
import Alligator from '../../svgs/Alligator'

export default function AllocatorTool(props) {
  const [preview, setPreview] = useState(null)
  const [errors, setErrors] = useState([])

  const _handleFileChange = (e) => {
    try {
      if (e.target.files.length) {
        const csv = e.target.files[0]

        upload(csv).then((res) => {
          setPreview(res)
        })
      } else {
        setPreview(null)
      }
    } catch (e) {
      setErrors(e => [...errors, e])
      console.log(errors)
    }
  }

  const _handleClearPreview = (e) => {
    setPreview(null)
  }

  const _handleSubmitPreview = (e) => {
    e.preventDefault()

    try {
      submit()
        .then(res => {
          setPreview(null)
          window.confirm('success!')
        })
    } catch (e) {
      setErrors(e => [...errors, e])
      console.log(errors)
    }
  }

  const _renderTableRow = () => {
    return preview.map(row => {
      return (
        <tr key={ `row${ row.subscriptionId }` }>
          <td>{ row.subscriptionId }</td>
          <td>{ row.nextOrderDate }</td>
          <td>{ row.variantId }</td>
        </tr>
      )
    })
  }

  const _renderTableData = () => {
    if (!preview) return <div className="allocator__empty-table">Upload some coffee to allocate!</div>

    return (
      <table>
        <thead>
          <tr>
            <th>Subscription ID</th>
            <th>Next Order Date</th>
            <th>New Variant ID</th>
          </tr>
        </thead>
        <tbody>
          { _renderTableRow() }
        </tbody>
      </table>
    )
  }

  const upload = async (csv) => {
    const formData = new FormData()
    formData.append('csvFile', csv)

    const path = '/api/allocator/upload'
    const options = { method: 'POST', body: formData }

    const response = await fetch(path, options)
    const responseJson = await response.json()

    if (response.status !== 200) throw Error(responseJson.body)

    return responseJson
  }

  const submit = async () => {
    const path = '/api/allocator/submit'
    const body = { subscriptions: preview }
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    }

    const response = await fetch(path, options)
    const responseJson = await response.json()

    if (response.status !== 200) throw Error(responseJson.body)

    return responseJson
  }

  return (
    <div className="allocator__container">

      { /** Upload Form */ }
      <div className="allocator__form-wrapper">
        <form onReset={ e => _handleClearPreview(e) }>
          <input
            id="csv-upload"
            type="file"
            accept=".csv"
            onChange={ e => _handleFileChange(e) }
            disabled={ !!preview }
          />
          <button type="reset" disabled={ !preview }>CLEAR</button>
        </form>
      </div>

      { /** Table Display */ }
      { _renderTableData() }

      { /** Submit Allocation */ }
      <div className="allocator__form-wrapper">
        <form>
          <div></div>
          <button
            className="allocator__submit"
            disabled={ !preview }
            onClick={ e => { _handleSubmitPreview(e) } }
          >
            Confirm Allocation <Alligator />
          </button>
        </form>
      </div>
    </div>
  )
}
