import React, { useState, useEffect } from 'react'
import { format } from 'date-fns'
import MaterialTable from 'material-table'
import {
  Button,
  Link,
  Snackbar,
  useTheme
} from '@material-ui/core'
import { Flag, FlagOutlined } from '@material-ui/icons'
import TastyChips from './TastyChips'
import SubscriptionTypeChip from './SubscriptionTypeChip'
import EditDrawer from './EditDrawer'
import DismissModal from './DismissModal'
import SaveModal from './SaveModal'

import './BaristaTool.scss'

export default function BaristaTool(props) {
  const theme = useTheme()
  const [snack, setSnack] = useState('')
  const [currentRow, setCurrentRow] = useState(null)
  const [rowChanges, setRowChanges] = useState({})
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)
  const [isDismissModalVisible, setIsDismissModalVisible] = useState(false)
  const [isSaveModalVisible, setIsSaveModalVisible] = useState(false)
  const [disableLoadMore, setDisableLoadMore] = useState(false)
  const [page, setPage] = useState(1)

  useEffect(() => {
    if (!data.length) {
      try {
        getSubscriptions()
          .then((responseJson) => {
            const subscriptions = responseJson.subscriptions

            setIsLoading(false)

            if (subscriptions && subscriptions.length) {
              setData(subscriptions)
            } else {
              setDisableLoadMore(true)
            }
          })
      } catch (e) {
        // TODO: handle error
        console.log(e)
      }
    }
  }, [data])

  const getSubscriptions = async (page) => {
    let path = `/api/barista/reviewable_subscriptions`

    if (page) path += `?page=${ page }`

    const response = await fetch(path)

    if (response.status !== 200) throw Error(response.json())

    return response.json()
  }

  const updateSubscription = async (subscription, newData) => {
    const path = `/api/barista/reviewable_subscriptions/${ subscription.subscription_id }`

    const options = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newData)
    }

    const response = await fetch(path, options)

    if (response.status !== 200) throw Error(response.json().body)

    return response.json()
  }

  const updateSubscriptionReviews = async (subId, body) => {
    const path = `/api/barista/reviewable_subscriptions/${ subId }/reviews`
    const options = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    }

    const response = await fetch(path, options)

    if (response.status !== 200) throw Error(response.json().body)

    return response.json()
  }

  const formatDate = (upNext) => {
    let upNextDate = new Date(upNext)
    const orderDateTime = upNextDate.getTime() // in UTC
    const timezoneOffset = upNextDate.getTimezoneOffset() * 60 * 1000

    upNextDate.setTime(orderDateTime + timezoneOffset) // set orderDate to local

    return format(upNextDate, 'iii M/dd')
  }

  const isThumbsUp = (rowData) => {
    if (!rowData.previous_coffee) return null

    const rating = rowData.user.rating_history.find(rating => rating.product_id === rowData.previous_coffee.product_id)

    if (!rating) return null

    return rating.thumbs_up
  }

  const findVariant = (product, targetGrindTypeId) => {
    return product.variants.find(variant => {
      return variant.options.find(option => option.key === 'grind' && option.id === targetGrindTypeId)
    })
  }

  const closeSnack = (event, reason) => {
    if (reason === 'clickaway') return

    setSnack(null)
  }

  const addChange = (key, change) => {
    let newRowChanges = rowChanges

    if (!(key in newRowChanges)) {
      newRowChanges[key] = {}
    }

    Object.assign(newRowChanges[key], change)

    setRowChanges(newRowChanges)
  }

  const handleLoadMoreClick = () => {
    const nextPage = page + 1

    setIsLoading(true)
    setSnack('More subscriptions, coming right up!')

    try {
      getSubscriptions(nextPage)
        .then(responseJson => {
          setPage(nextPage)
          setIsLoading(false)

          const subscriptions = responseJson.subscriptions

          if (subscriptions) {
            if (subscriptions.length) {
              setData([...data, ...subscriptions])
            } else if (!subscriptions.length) {
              setDisableLoadMore(true)
            }
          }
          setSnack(null)
        })
    } catch (e) {
      setSnack(`Error: ${ e }`)
    }
  }

  const handleRowUpdate = async (update) => {
    const index = currentRow.tableData.id

    setIsLoading(true)
    setIsDrawerVisible(false)

    const subscription = data[index]
    const subscriptionData = {}

    if (update.newCoffee) {
      const grindTypeId = subscription.grind_type_option_value_id
      const newCoffeeVariant = findVariant(update.newCoffee, grindTypeId)

      if (!newCoffeeVariant) {
        setSnack('Error: Subscription\'s grind type not available for selected product')
        setIsLoading(false)
        return
      }

      subscriptionData.queue_variant_ids = [newCoffeeVariant.variant_id]
      subscriptionData.should_send_email = update.shouldSendEmail
    }

    if (update.newTasteGroups) {
      subscriptionData.taste_group_ids = update.newTasteGroups.map(tg => tg.id)
    }

    try {
      const responseJson = await updateSubscription(subscription, subscriptionData)
      const reviewedSubscription = await responseJson.subscription

      if (reviewedSubscription) {
        updateSubscriptionReviews(reviewedSubscription.subscription_id, { state: 'reviewed' })
          .then(() => {
            data.splice(index, 1)

            setData([...data])
            setIsLoading(false)
            setSnack('Successfully updated subscription!')
          })
      } else if (responseJson.error) {
        setData([...data])
        setIsLoading(false)
        setSnack(`Update failed: ${ responseJson.error }`)
      }
    } catch (e) {
      console.log({ e })

      setSnack('Something went wrong :(')
    }
  }

  const handleSaveChanges = () => {
    handleRowUpdate(rowChanges[currentRow.subscription_id])
    setIsSaveModalVisible(false)
  }

  const handleCoffeeSelected = (product, shouldSendEmail = false) => {
    currentRow.next_coffee = product
    addChange(currentRow.subscription_id, { newCoffee: product, shouldSendEmail: shouldSendEmail })
  }

  const handleTasteGroupsChanged = (tasteGroups) => {
    currentRow.taste_groups = tasteGroups
    addChange(currentRow.subscription_id, { newTasteGroups: tasteGroups })
  }

  const handleOpenEditDrawer = (e, rowData) => {
    setCurrentRow(rowData)
    setIsDrawerVisible(true)
  }

  const handleClickDismissAction = (rowData) => {
    const key = rowData.subscription_id

    setCurrentRow(rowData)

    if (!rowChanges[key]) {
      handleOpenDismissModal()
    } else {
      handleOpenSaveModal()
    }
  }

  const handleOpenSaveModal = () => {
    setIsSaveModalVisible(true)
  }

  const handleDismissSaveModal = () => {
    setIsSaveModalVisible(false)
  }

  const handleOpenDismissModal = (rowData) => {
    setIsDismissModalVisible(true)
  }

  const handleCloseDismissModal = () => {
    setCurrentRow(null)
    setIsDismissModalVisible(false)
  }

  const handleCloseEditDrawer = (e) => {
    setIsDrawerVisible(false)
  }

  const handleRowDismiss = () => {
    setIsDismissModalVisible(false)

    const dataDelete = [...data]
    const index = currentRow.tableData.id

    try {
      updateSubscriptionReviews(currentRow.subscription_id, { state: 'dismissed' })
        .then(() => {
          const phrases = ['Bye Felicia~', 'Fuggedaboutit!', 'Buh-bye!', 'Sashay, away.']
          const randomPhrase = phrases[Math.floor(Math.random() * phrases.length)]

          dataDelete.splice(index, 1)

          setCurrentRow(null)
          setData([...dataDelete])
          setSnack(`Subscription dismissed. ${ randomPhrase }`)
        })
    } catch (error) {
      console.log({ error })
      setSnack('Something went wrong :(')
    }
  }

  const handleClickFlagAction = (rowData) => {
    const newData = [...data]
    const index = rowData.tableData.id

    newData[index].is_flagged = !newData[index].is_flagged

    setData(newData)

    try {
      updateSubscriptionReviews(newData[index].subscription_id, { is_flagged: rowData.is_flagged })
    } catch (error) {
      console.log({ error })
      setSnack('Something went wrong :(')
    }
  }

  const renderEmail = (row) => {
    const user = row.user

    if (!user) return (<span>No user data</span>)

    return <Link href={ `https://app.drinktrade.com/admin/users/${ user.user_id }/edit` } target="_blank">{ user.email }</Link>
  }

  const renderThumbsUpRatio = (row) => {
    const user = row.user

    if (!user) return (<span>No user data</span>)

    return (
      <span style={ parseFloat(user.thumbs_up_ratio) <= 0.59 ? { color: 'red' } : {} }>
        { `${ Math.round(parseFloat(user.thumbs_up_ratio) * 100) }%` }
      </span>
    )
  }

  const renderPreviousCoffee = (row) => {
    const prevCoffee = row.previous_coffee

    if (!prevCoffee) return (<span></span>)

    return (
      <span style={ isThumbsUp(row) === false ? { color: 'red' } : {} }>
        <div className='bold'>{ prevCoffee.brand.name }</div>
        { prevCoffee.name }
      </span>
    )
  }

  const renderNextCoffee = (row) => {
    let nextCoffee = row.next_coffee

    if (!nextCoffee) return (<span></span>)

    return (
      <span className='next-coffee-select'>
        <div className='bold'>{ nextCoffee.brand.name }</div>
        { nextCoffee.name }
      </span>
    )
  }

  const renderTasteGroups = (row) => {
    let tasteGroups = row.taste_groups

    if (!tasteGroups) return (<span></span>)

    return <TastyChips tasteGroups={ tasteGroups } isEditable={ false } />
  }

  return (
    <div className="table__container">
      {
        currentRow &&
        <EditDrawer
          isVisible={ isDrawerVisible }
          data={ currentRow }
          changes={ rowChanges[currentRow.subscription_id] }
          handleOnClose={ handleCloseEditDrawer }
          handleTasteGroupsChanged={ handleTasteGroupsChanged }
          handleCoffeeSelected={ handleCoffeeSelected }
        />
      }
      <DismissModal isOpen={ isDismissModalVisible } handleClose={ handleCloseDismissModal } handleDismiss={ handleRowDismiss } />
      <SaveModal isOpen={ isSaveModalVisible } handleClose={ handleDismissSaveModal } handleSave={ handleSaveChanges } />
      <div className="total-entries-count">
        <span>{ `${ data.length } results` } </span>
      </div>
      <MaterialTable
        title=''
        columns={ [
          {
            title: 'Up Next', field: 'next_order_date', cellStyle: theme.centerStyle, searchable: false,
            render: rowData => <span>{ formatDate(rowData.next_order_date) }</span>
          },
          {
            title: 'Email', field: 'user.email', cellStyle: theme.centerStyle,
            render: rowData => renderEmail(rowData)
          },
          {
            title: 'Sub Type', field: 'subscription_type', cellStyle: theme.centerStyle,
            render: rowData => <SubscriptionTypeChip subscriptionType={ rowData.subscription_type } />
          },
          { title: 'Nth Bag', field: 'nth_order', cellStyle: theme.centerStyle, searchable: false },
          {
            title: 'Thumbs Up Ratio', field: 'user.thumbs_up_ratio', cellStyle: theme.centerStyle, searchable: false,
            render: rowData => renderThumbsUpRatio(rowData)
          },
          {
            title: 'Previous Coffee', field: 'previous_coffee.name', cellStyle: theme.centerStyle,
            render: rowData => renderPreviousCoffee(rowData)
          },
          {
            title: 'Next Coffee', field: 'next_coffee.name', cellStyle: theme.centerStyle,
            render: rowData => renderNextCoffee(rowData)
          },
          {
            title: 'User Taste Types', field: 'taste_groups', cellStyle: theme.centerStyle, sorting: false, searchable: false,
            render: rowData => renderTasteGroups(rowData)
          }
        ] }
        data={ data }
        options={ {
          headerStyle: theme.tableHeader,
          search: true,
          sorting: true,
          paging: false,
          draggable: false
        } }
        actions={ [
          rowData => ({
            icon: rowData.subscription_id in rowChanges ? 'save' : 'cancel',
            iconProps: {
              color: rowData.subscription_id in rowChanges ? 'primary' : 'secondary',
              fontSize: 'large',
            },
            tooltip: (rowData.subscription_id in rowChanges ? 'Save & Dismiss' : 'Dismiss'),
            onClick: (e, rowData) => handleClickDismissAction(rowData)
          }),
          rowData => ({
            icon: () => rowData.is_flagged ? <Flag color='secondary' fontSize='large' /> : <FlagOutlined color='secondary' fontSize='large' />,
            tooltip: rowData.is_flagged ? 'Remove flag' : 'Flag for review',
            onClick: (e, rowData) => handleClickFlagAction(rowData)
          }),
        ] }
        localization={ { header: { actions: '' } } }
        isLoading={ isLoading }
        onRowClick={ handleOpenEditDrawer }
      />
      <div className="load-more__container">
        <Button variant="contained" color="primary" onClick={ handleLoadMoreClick } disabled={ isLoading || disableLoadMore }>
          { disableLoadMore ? 'No More' : 'Load More' }
        </Button>
      </div>
      {
        snack &&
        <Snackbar
          anchorOrigin={ {
            vertical: 'bottom',
            horizontal: 'left',
          } }
          open={ snack ? true : false }
          autoHideDuration={ 4000 }
          onClose={ closeSnack }
          message={ snack }
          action={
            <Button color="primary" size="small" onClick={ closeSnack }>
              close
            </Button>
          }
        />
      }
    </div>
  )
}