import React from 'react'
import PropTypes from 'prop-types'
import TastyChip from './TastyChip'

import './CoffeeCard.scss'

const CoffeeCard = ({ children, product }) => {
  return (
    <div className="coffee-card">
    <div className="coffee-card__header">
      <div className="coffee-card__name">
        <h5>{ product.brand.name }</h5>
        <h3>{ product.name }</h3>
      </div>
      <div className="coffee-card__chip">
        <TastyChip tasteGroup={ product.taste_group } />
      </div>
    </div>
    { children }
  </div>
  )
}


CoffeeCard.propTypes = {
  product: PropTypes.object.isRequired
}

export default CoffeeCard