import React from 'react'
import PropTypes from 'prop-types'

const QuizAnswer = ({ question, answer }) => (
  <li>
    <span className="question-text">{ question }</span>
    <span className="answer-text">{ answer }</span>
  </li>
)

QuizAnswer.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired
}

export default QuizAnswer