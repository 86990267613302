import React from 'react'
import MaterialTable from 'material-table'
import { Link } from 'react-router-dom'


export default function SupplierList(props) {
  const { match, suppliers, tableHeader, centerStyle } = props

  return (
    <div className='table__container'>
      <MaterialTable
        columns={ [
          { title: 'Short Code', field: 'short_code', cellStyle: centerStyle },
          { title: 'Name', field: 'name', cellStyle: centerStyle, defaultSort: 'asc' },
          { title: 'Active', field: 'active', cellStyle: centerStyle },
        ].map(c => (
          {
            render: row => (
              <Link to={ { pathname: `${match.url}/${ row.slug }` } }>
                { row[c.field].toString() }
              </Link>
            ),
            ...c
          }
        )) }
        data={ suppliers }
        title=''
        options={ {
          headerStyle: tableHeader,
          paging: false,
          sorting: true
        } }
      />
    </div>
  )
}