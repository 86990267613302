import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './DeallocatorTool.scss'

export default function DeallocatorTool(props) {
  const [preview, setPreview] = useState(null)
  const [errors, setErrors] = useState([])

  const _handleFileChange = (e) => {
    try {
      if (e.target.files.length) {
        const csv = e.target.files[0]

        upload(csv).then((res) => {
          setPreview(res)
        })
      } else {
        setPreview(null)
      }
    } catch (e) {
      setErrors(e => [...errors, e])
      console.log(errors)
    }
  }

  const _handleClearPreview = (e) => {
    setPreview(null)
  }

  const _handleSubmitPreview = (e) => {
    e.preventDefault()

    try {
      submit()
        .then(res => {
          setPreview(null)
          window.confirm('success!')
        })
    } catch (e) {
      setErrors(e => [...errors, e])
      console.log(errors)
    }
  }

  const _renderTableRow = () => {
    return preview.map(row => {
      return (
        <tr key={ `row${ row.subscriptionId }` }>
          <td>{ row.subscriptionId }</td>
          <td>{ row.productId }</td>
        </tr>
      )
    })
  }

  const _renderTableData = () => {
    if (!preview) return <div className="allocator__empty-table">Upload CSV to remove all (productId) variants from (subscriptionId)'s' Queue</div>

    return (
      <table>
        <thead>
          <tr>
            <th>Subscription ID</th>
            <th>Product ID</th>
          </tr>
        </thead>
        <tbody>
          { _renderTableRow() }
        </tbody>
      </table>
    )
  }

  const upload = async (csv) => {
    const formData = new FormData()
    formData.append('csvFile', csv)

    const path = '/api/deallocator/upload'
    const options = { method: 'POST', body: formData }

    const response = await fetch(path, options)
    const responseJson = await response.json()

    if (response.status !== 200) throw Error(responseJson.body)

    return responseJson
  }

  const submit = async () => {
    const path = '/api/deallocator/submit'
    const body = { subscriptions: preview }
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    }

    const response = await fetch(path, options)
    const responseJson = await response.json()

    if (response.status !== 200) throw Error(responseJson.body)

    return responseJson
  }

  return (
    <div className="deallocator">
      <div className="instructions">
        <h1>Instructions</h1>
        <ol>
          <li><p>Download the sample CSV file to get started.</p></li>
          <li><p>Remove the default values and add a new row for each SubscriptionID you want to remove ProductID from.</p></li>
          <li><p>Include the headers when you upload the CSV.</p></li>
          <li><p>Once submitted, process may take several minutes to complete deallocation.</p></li>
        </ol>

        <button><Link to="/samples/deallocator_sample.csv" target="_blank" download>Download Sample CSV</Link></button>
      </div>
      <div className="deallocator__form-wrapper">
        <form onReset={ e => _handleClearPreview(e) }>
          <input
            id="csv-upload"
            type="file"
            accept=".csv"
            onChange={ e => _handleFileChange(e) }
            disabled={ !!preview }
          />
          <button type="reset" disabled={ !preview }>CLEAR</button>
        </form>

      </div>

      { _renderTableData() }

      <div className="deallocator__form-wrapper">
        <form>
          <div></div>
          <button
            className="deallocator__submit"
            disabled={ !preview }
            onClick={ e => { _handleSubmitPreview(e) } }
          >
            Confirm Deallocation
          </button>
        </form>
      </div>
    </div>
  )
}
